import React from "react";
import { useState, useEffect } from "react";
import { randomNumber } from "../../helpers";
import './Page.scss';
import Grid from "../gridSystem/Grid/Grid";
import GridColumn from "../gridSystem/GridColumn/GridColumn";
import OverviewL0 from "../Overview/OverviewL0";
import OverviewL1 from "../Overview/OverviewL1";

const Page = ({ data, loading, error }) => {
    const [fixScore, setFixScore] = useState(null);

    useEffect(() => {
        let score;
        if (loading) {
            score = randomNumber(setFixScore);
        } else if (error) {
            setFixScore(0);
        } else {
            setFixScore(null);
        }
        return () => clearInterval(score);
    }, [loading, error]);

    return (
        <div className="Page">
            <Grid>
                <GridColumn size={12}>
                    <OverviewL0 data={data} loading={loading} score={fixScore} />
                </GridColumn>
                <GridColumn size={12}>
                    <OverviewL1
                        data={data}
                        loading={loading}
                    />
                </GridColumn>
            </Grid>
        </div >
    )
}

export default Page;