import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { HiMenu } from "react-icons/hi";
import { getUsers } from "../../api/typeform";
import constants from "../../constants";
import Alert from "../Alert/Alert";
import Button from "../Button/Button";
import LoginButton from "../LoginButton/LoginButton";
import Search from "../Search/Search";

const Header = ({ user, handleUserSelect }) => {
    const { logout } = useAuth0();
    const [openMenu, setOpenMenu] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [error, setError] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        setIsAdmin(user[constants.namespace.role] === constants.roles.admin);
    }, [user, isAdmin]);

    useEffect(() => {
        // if admin - get users
        if (isAdmin) {
            getUsers(user.__raw).then(res => {
                if (res.status === 200) {
                    setUsers(res.users)
                } else {
                    setError(true);
                }
            });
        }
    }, [user, isAdmin]);

    return (
        <>
            {!!error && <Alert />}
            <div className="App__header">
                <div className="App__logo">
                    <img alt="TransitionView logo" src="https://lh3.googleusercontent.com/j8HZN0_FPqvP12UFN3Y5V8B7FGLX5WfRMi71tXEq8hIUZ99OmILfHreKTxIJ2XXJl0uQraFvGCXANJU6ttAVODJtkxhTzpOqvsrTex8dNL3LJH1H-wvYhqfGZhgccY4e7PSO8lthcg=w2400" />
                </div>
                <div className="App__headerRight--mobile">
                    <div className="App__menuButton"><Button color='transparent' onClick={() => setOpenMenu(!openMenu)}><HiMenu /></Button></div>
                    {openMenu && (
                        <div className="App__menu">
                            <p className="App__menuItem">{user.nickname} - {user.company}</p>
                            <p className="App__menuItem" onClick={() => logout({ returnTo: window.location.origin })}>Log out</p>
                        </div>
                    )}
                </div>
                <div className="App__headerRight">
                    {!!isAdmin && (
                        <Search items={users} onSelect={handleUserSelect} />
                    )}
                    <p className="App__headerName">{user.nickname} - {user.company}</p>
                    <LoginButton />
                </div>
            </div>
        </>
    )
}

export default Header;