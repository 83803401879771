import { cloneElement, useEffect, useState } from "react";
import { getTypeformByIdAndToken, getTypeformByToken } from "../api/typeform";
import { randomNumber } from "../helpers";
import { useNavigate } from "react-router-dom";

const DataContainer = props => {
    const { company, typeformToken, typeformId, token, children } = props;
    const [score, setScore] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {

        setLoading(true);
        const randomScore = randomNumber(setScore);

        const handleData = data => {
            if (data.status !== 200) {
                throw data;
            }
            if (data.status === 500) throw data;
            if (data.total_items === 0) {
                navigate("/typeform");
            } else {
                setScore(data.score);
                setLoading(false);
                clearInterval(randomScore);
                setData(data.children)
            }
        }
        if (typeformId) {
            getTypeformByIdAndToken(typeformId, typeformToken, token)
                .then(data => handleData(data))
                .catch(() => {
                    setError(true);
                    setLoading(false);
                });
        } else {
            getTypeformByToken(typeformToken, token)
                .then(data => handleData(data))
                .catch(() => {
                    setError(true);
                    setLoading(false);
                });
        }
        return () => clearInterval(randomScore);
    }, [token, company, typeformId, typeformToken, navigate]);

    return cloneElement(children, { ...props, score, data, loading, error });
}

export default DataContainer;