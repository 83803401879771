export const roundTo10Decimals = number => Math.round(number * 10) / 10;

export const generateToken = () => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const length = 7;
    let token = "";
    for (let i = 0; i < length; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        token += chars.substring(randomNumber, randomNumber + 1);
    }
    return token;
}

export const randomNumber = (callback) => setInterval(() => callback(score => score < 95 ? score + Math.floor(Math.random() * 5) + 1 : 100), 250);

export const loginAuth0Params = (company, companyId, typeformId, tag, invitation, organization, organization_name) => ({
    scope: 'read:current_user',
    screen_hint: 'signup',
    typeformToken: generateToken(),
    company: company || '',
    companyId: companyId || process.env.REACT_APP_DEFAULT_COMPANY_ID,
    ...(typeformId ? { typeformId } : {}),
    ...(tag ? { tag } : {}),
    ...(invitation ? { invitation } : {}),
    ...(organization ? { organization } : {}),
    ...(organization_name ? { organization_name } : {})
});