import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import constants from "../../../constants";
import ContentfulContainer from "../../../container/ContentfulContainer";
import DataContainer from "../../../container/DataContainer";
import { loginAuth0Params } from "../../../helpers";
import Button from "../../Button/Button";
import Contact from "../../Contact/Contact";
import ContentfulPage from "../../ContentfulPage/ContentfulPage";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import Layout from "../../Layout/Layout";
import Popup from "../../Popup/Popup";

const ResultPage = ({ preview }) => {
    const [searchParams] = useSearchParams();
    const company = searchParams.get("company");
    const typeform = searchParams.get("form");
    const tag = searchParams.get("tag");
    const invitation = searchParams.get("invitation");
    const organization = searchParams.get("organization");
    const organization_name = searchParams.get("organization_name");
    const company_ref = searchParams.get("company_ref");
    const domRef = useRef();
    const { isAuthenticated, user, isLoading, getIdTokenClaims, loginWithRedirect } = useAuth0();
    const [thisUser, setUser] = useState(null);
    const [openContact, setOpenContact] = useState(false);
    const [hasBeenOpen, setHasBeenOpen] = useState(false);
    const [userInView, setUserInView] = useState(null);
    const OFFSET_THRESHHOLD = 150;

    useEffect(() => {
        getIdTokenClaims().then(user => {
            setUser(user);
            if (user) {
                setUserInView(user);
            }
        });
    }, [user, getIdTokenClaims]);

    useLayoutEffect(() => {
        const ref = domRef.current;
        if (ref && !hasBeenOpen && thisUser && (!thisUser.tag || thisUser.tag !== constants.tags.ns102.id)) {
            const getTopPosition = () => {
                const topPosition = ref.scrollTop;
                if (OFFSET_THRESHHOLD < topPosition) {
                    setHasBeenOpen(true);
                    setOpenContact(true);
                    return () => ref.removeEventListener('scroll', getTopPosition)
                }
            }
            ref.addEventListener('scroll', getTopPosition);
            getTopPosition();
            return () => ref.removeEventListener('scroll', getTopPosition);
        }
    });

    const handleUserSelect = user => {
        if (user) {
            setUserInView(user);
        }
    }

    if (isLoading) {
        return;
    }

    if (!isAuthenticated && !isLoading) {
        loginWithRedirect(loginAuth0Params(company, company_ref, typeform, tag, invitation, organization, organization_name))
    }

    return thisUser && (
        <div className='App--background' ref={domRef}>
            <Header user={thisUser} handleUserSelect={handleUserSelect} />
            <DataContainer typeformId={(thisUser['survey/member'] && thisUser['survey/id']) || thisUser.typeformId} typeformToken={(thisUser['survey/member'] && thisUser['survey/token']) || thisUser.typeformToken} token={thisUser.__raw} company={thisUser.companyId}>
                <Layout onClick={() => setOpenContact(!openContact)} name={thisUser.nickname[0].toUpperCase() + thisUser.nickname.slice(1)} tag={thisUser && thisUser.tag} />
            </DataContainer>
            <div className="App__rightCorner">
                {openContact && (
                    <div className="App__popup">
                        <Popup onClick={() => setOpenContact(false)}><Contact user={thisUser} /></Popup>
                    </div>
                )}
                <div className="App__buttonWrapper">
                    <Button color="opacity" onClick={() => setOpenContact(!openContact)}><div className="App__button">Contact us</div></Button>
                </div>
            </div>
            <ContentfulContainer preview={preview} slug='result'>
                <ContentfulPage />
            </ContentfulContainer>
            <Footer />
        </div>
    )
}

export default ResultPage;