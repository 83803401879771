import React from "react";
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import './Search.scss';

const Search = ({ items, onSelect }) => (
    <Typeahead
        id="user-search"
        className="Search"
        onChange={(selected) => onSelect(selected[0])}
        options={items}
        labelKey={option => {
            const name = option.nickname[0].toUpperCase() + option.nickname.slice(1);
            const company = option.company[0].toUpperCase() + option.company.slice(1);
            return `${name} • ${option.email} • ${company}`;
        }}
        filterBy={['name', 'company', 'email']}
        placeholder="Search user.."
        renderMenu={(results, menuProps) => (
            <Menu {...menuProps}>
                <div className="Search__menu" >
                    {results.map((result, index) => (
                        <MenuItem key={result.id} option={result} position={index}>
                            <div className="Search__item">
                                <p className="Search__name">{result.nickname} &#183; {result.company}</p>
                                <p className="Search__sub">{result.email} </p>
                            </div>
                        </MenuItem>
                    ))}
                </div>
            </Menu>
        )}
    />
)

export default Search;